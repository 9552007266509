content {
    &.full-screen {
        z-index: 1001 !important;

        .ps > .ps__rail-y {
            z-index: 0;
        }
    }
}

mwlResizable {
    box-sizing: border-box; // required for the enableGhostResize option to work
}

.danger-snackbar {
    background: #ff4444 !important;
    --mdc-snackbar-container-color: #ff4444 !important;
    color: white !important;
    --mdc-snackbar-supporting-text-color: white !important;
    --mat-snack-bar-button-color: white !important;
}

.success-snackbar {
    background: #2E7D32 !important;
    --mdc-snackbar-container-color: #2E7D32 !important;
    color: white !important;
    --mdc-snackbar-supporting-text-color: white !important;
    --mat-snack-bar-button-color: white !important;
}

.notificacao-snackbar {
    background: #F1F5F9 /* slate.100 */ !important;
    --mdc-snackbar-container-color: #F1F5F9 /* slate.100 */ !important;
    color: black !important;
    --mdc-snackbar-supporting-text-color: black !important;
    --mat-snack-bar-button-color: black !important;
}

div.menu-busca {
    max-width: 600px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    top: 8px !important;
}

div.menuNotificacao {
    max-width: 1000px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    top: 8px !important;
}

div.menuTipoDocumento {
    width: 400px;
}

div.menu-busca {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .custom-search-header {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        margin-left: 15px;
        margin-right: 15px;

        .title {
            font-size: 18px;
            margin-bottom: 5px;
            margin-right: 3px;
        }
    }

    .campo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-left: 15px;
        margin-right: 15px;

        &.selected {
            cursor: default;
            font-weight: bold;
        }
    }
}

div.menu-sort {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .sort-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &.selected {
            font-weight: bold;
        }
    }
}

.datasBackdrop {
    background-color: rgba(204, 204, 204, 0.5);
}

.datasValidateBackdrop {
    background: repeating-linear-gradient(
            45deg,
            transparent,
            transparent 10px,
            rgba(94, 0, 0, 0.521) 10px,
            rgba(94, 0, 0, 0.521) 20px
    );
    opacity: 0.1 !important;
}
